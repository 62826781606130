import logo from './logo.svg';
import './App.css';
import { useState } from 'react';
import ReactDOM from 'react-dom/client';

function App() {

  
  const [inputs, setInputs] = useState({
     tti: "",
     tto: "",
      tmt: ""
     // tmo: ""
    })

  
  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    setInputs(values => ({...values, [name]: value}))
    
  }
  
  const handleSubmit = (event) => {
    event.preventDefault();
    
    if(inputs.tti && inputs.tto && inputs.tmt) {
    
      const scoreFactor = ((inputs.tto - inputs.tti) / (inputs.tmt - inputs.tti));

      document.getElementById('sf').innerHTML = scoreFactor;
      
      const tu_30 = document.getElementById('tu_30').value;
      const tu_40 = document.getElementById('tu_40').value;
      const tu_50 = document.getElementById('tu_50').value;
      const tu_60 = document.getElementById('tu_60').value;
      const tu_70 = document.getElementById('tu_70').value;
      const tu_80 = document.getElementById('tu_80').value;
      
      document.getElementById("c_30").innerHTML = (tu_30 / scoreFactor).toFixed(2);
      document.getElementById("c_40").innerHTML = (tu_40 / scoreFactor).toFixed(2);
      document.getElementById("c_50").innerHTML = (tu_50 / scoreFactor).toFixed(2);
      document.getElementById("c_60").innerHTML = (tu_60 / scoreFactor).toFixed(2);
      document.getElementById("c_70").innerHTML = (tu_70 / scoreFactor).toFixed(2);
      document.getElementById("c_80").innerHTML = (tu_80 / scoreFactor).toFixed(2);
      
      document.getElementById('mv').style.display = 'block';

      
    }
    
    
    
  }
  
  const chooseMV = (event) => {
   event.preventDefault();
    
    window.insertVal(event.target.value);
}

  
  //<tr><td>Temp Into Tank:</td><td><input type="number" name="tmo"  value={inputs.tmo} onChange={handleChange}  /></td></tr>
  return (
    <form onSubmit={handleSubmit}>
    
    <table align="center"><tr><td>Temp Out:</td><td><input type="number" name="tto" value={inputs.tto} onChange={handleChange} /></td></tr>
    <tr><td>Temp In:</td><td><input type="number" name="tti"  value={inputs.tti} onChange={handleChange}  /></td></tr>
    <tr><td>Temp Maintained:</td><td><input type="number" name="tmt"  value={inputs.tmt} onChange={handleChange} /></td></tr>
   
    </table>
    <br />
    <input type="submit" value="Calculate" />
    <br /><br />
 
   <div id="sf">...</div>
   
  <div id="mv" ><b>Select Mixing Valve:</b><br />
  <select onChange={chooseMV} >
  <option value=''></option>
  <option value='2.3'>5000-3</option>
 <option value=' 2.3'>5002-B3</option>
 <option value=' 2.3 '>5002-C3</option>
  <option value='4.1'>5124-HF-C1</option>
  <option value='4.1'>5125-HF-C1</option>
  </select>
  </div>

  
    <br /><br />
    <table border="0" align="center">
    <thead>
    <tr>
      <th>Tank Size</th>
      <th>Tank Usable<br />Capacity</th>
      <th>Capacity with<br />Mix Valve</th>
    </tr>
    </thead>
    <tbody>
    <tr>
      <td>30 Gal</td>
      <td><input type="text" id="tu_30" defaultValue="25.5" onChange={handleChange}  /></td>
      <td id="c_30"></td>
    </tr>
    <tr>
      <td>40 Gal</td>
      <td><input type="text" id="tu_40" defaultValue="34" onChange={handleChange}  /></td>
      <td id="c_40"></td>
    </tr>
    <tr>
      <td>50 Gal</td>
      <td><input type="text" id="tu_50" defaultValue="42.5" onChange={handleChange}  /></td>
      <td id="c_50"></td>
    </tr>
    <tr>
      <td>60 Gal</td>
      <td><input type="text" id="tu_60" defaultValue="51" onChange={handleChange}  /></td>
      <td id="c_60"></td>
    </tr>
    <tr>
      <td>70 Gal</td>
      <td><input type="text" id="tu_70" defaultValue="59.5" onChange={handleChange}  /></td>
      <td id="c_70"></td>
    </tr>
    <tr>
      <td>80 Gal</td>
      <td><input type="text" id="tu_80" defaultValue="68" onChange={handleChange} /></td>
      <td id="c_80"></td>
    </tr>
    </tbody>
    </table>
    
    
        
    </form>
  )
}

export default App;
